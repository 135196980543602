<template>
  <div>
    <div class="header">
      <slot name="left">
        <img
          class="icon back"
          src="~@/assets/image/icon/back.png"
          alt="icon"
          @click="$utils.goBack"
        />
      </slot>
      <p class="title">{{ title }}</p>
      <slot name="right">
        <div class="right">
          <img
            class="icon"
            src="~@/assets/image/icon/bell-active.png"
            alt="icon"
            @click="goMessage"
          />
          <img
            class="icon ml"
            src="~@/assets/image/icon/system.png"
            alt="icon"
            @click="goConfig"
          />
        </div>
      </slot>
    </div>
    <van-divider class="divider" />
  </div>
</template>
<script>
export default {
  name: 'Myheader',
  data() {
    return {}
  },
  props: {
    title: {
      type: String,
      default: '个人页面',
    },
  },
  created() {},
  computed: {},
  methods: {
    goMessage() {
      this.$router.push('/message')
    },
    goConfig() {
      this.$router.push('/my/setting')
    },
  },
}
</script>
<style lang="less" scoped>
.back {
  position: absolute;
  left: 60px;
  top: 75px;
}
.ml {
  margin-left: 60px;
}
.header {
  padding: 64px 64px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .title {
    font-size: 64px;
  }
  .icon {
    height: 60px;
    width: auto;
  }
  .right {
    position: absolute;
    right: 64px;
  }
}
.divider {
  margin: 40px 0;
}
</style>
