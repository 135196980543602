<template>
  <div class="list-container">
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      :offset="5"
      @load="getList"
    > 
      <list-item v-for="(l, i) in list" :key="i" :item="l" />
    </van-list>
  </div>
</template>

<script>
import { List } from 'vant';
import ListItem from './ListItem'
import { getContByTopic } from '@/api/content'
export default {
  name: 'ListContainer',
  components: {
    ListItem,
    [List.name]: List
  },
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      finished: false,
      list: [],
      queryParams: {
        pageNo: 1,
        pageSize: 10
      }
    }
  },
  created() {
    this.getList()
  },
  methods: {
    reset() {
      // 重置
      this.list = []
      this.queryParams.pageNo = 1
      this.finished = false
    },
    getList() {
      // 获取数据
      const { pageNo, pageSize } = this.queryParams
      const query = {
        id: this.id,
        pageNo,
        pageSize
      }

      this.loading = true
      getContByTopic(query)
      .then(res => {
        if (res && res.success) {
          this.loading = false
          const { records, total } = res.result
          this.$emit('getTotal', total)
          this.list = this.list.concat(records)
          this.queryParams.pageNo += 1
          if (this.list.length >= total) {

            this.finished = true
          }
        }
      })
     
    },
  }
}
</script>

<style scoped lang="less">

</style>