import Vue from 'vue'
import  Myheader  from '@/components/common/Myheader';
import Footer from '@/components/layout/footer.vue'
import Search from '@/components/common/search';

export default{
  install(){
    Vue.component('my-header', Myheader)
    Vue.component('Footer', Footer)
    Vue.component('search', Search)
  }
}