<template>
  <div class="search-page">
    <!-- 搜索 -->
    <search ref="search" @search="handleSearch" />
    <!-- 关键词 -->
    <div class="words">
      <span>热门搜索:</span>
      <van-tag class="tag" round v-for="(t, i) in keywords" :key="i" type="primary" @click="searchByWord(t)">{{t}}</van-tag>
    </div>
    <!-- 列表 -->
    <div class="list-wrapper">
      <list ref="list" />
    </div>
  </div>
</template>

<script>
import { Tag } from 'vant';
import List from './components/List';
import { contentSearchWords } from '@/api/content'

export default {
  name: 'SearchPage',
  components: {
    List,
    [Tag.name]: Tag
  },
  data() {
    return {
      keywords: []
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      const { s } = this.$route.query
      let string = decodeURIComponent(s || '')
      this.keyword = string
      this.$refs.search.keyword = string
      
      this.handleSearch(string)
      this.getSearchWords()
    },
    getSearchWords() {
      contentSearchWords()
      .then(res => {
        if (res && res.success) {
          const words = res.result || []

          this.keywords = words.filter(i => !!i)
        }
      })
    },
    handleSearch(keyword) {
      this.$router.replace({query:{s:keyword}})
      this.$refs.list.load(keyword)
    },
    searchByWord(word) {
      this.$refs.search.keyword = word
      this.handleSearch(word)
    }
  }
}
</script>

<style lang="less" scoped>
  @import '~@/assets/style/common.less';

  .search-page {
    padding: 164px 64px 0;
  }
  .words {
    margin: 10px 0;
    font-size: 32px;
    color: @font-gray-2;
  }
  .tag {
    background-color: @primary-color;
    padding: 12px 24px;
    min-width: 80px;
    justify-content: center;
    margin: 30px 0 0 40px;
  }
</style>