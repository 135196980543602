import createPersistedState from "vuex-persistedstate"
import Vuex from 'vuex'
import Vue from 'vue'
Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        // 用户信息
        user: {},
        loadingShow: false,
        // 问卷信息
        questionnaireInfo:{},
    },
    getters: {
        user(state) {
            return state.user
        },
        getLoading(state) {
            return state.loadingShow
        },
        getQuestionnaireInfo(state){
            return state.questionnaireInfo
        }
    },
    mutations: {
        setUser(state, user) {
            Object.assign(state.user, user)
        },
        setLoading(state) {
            state.loadingShow = !state.loadingShow
        },
        setQuestionnaireInfo(state,info){
            state.questionnaireInfo = info
        }
    },
    actions: {
        setUser({ commit }, user) { commit('setUser', user) },
        // setLoading({ commit }) { commit('setLoading') }
        setLoading(context){
            context.commit('setLoading')
        },
        setQuestionnaireInfo(context,info){
            context.commit('setQuestionnaireInfo',info)
        }
        
    },
    plugins: [createPersistedState({
        storage: window.localStorage,
        reducer(val) {
            return {
                user: val.user,
            }
        }
    })]
})

export default store
// export default {
//     state: {
//         // 用户信息
//         user: {}
//     },
//     getters: {
//         user: (state) => state.user
//     },
//     mutations: {
//         setUser(state, user) {
//             Object.assign(state.user, user)
//         }
//     },
//     actions: {
//         setUser: ({ commit }, user) => commit('setUser', user)
//     }
// }