<template>
  <div class="list-item" @click="goContent">
    <div class="info">
      <div class="left">
        <p class="title">
          {{ item.contentName }}
        </p>
        <p class="des">
          {{ item.contentSummary }}
        </p>
      </div>
      <div class="right">
        <van-image class="pic" lazy-load show-loading :src="item.cover">
        </van-image>
      </div>
    </div>
    <div class="footer">
      <div class="left">
        <van-icon name="eye-o" class="icon" />
        <span class="mr">{{ item.readingNum }}</span>
        <van-icon
          name="star-o"
          class="icon"
          v-if="item.collectionStatus == 0"
        />
        <van-icon name="star" v-else />
        <span class="mr">{{ item.collectionNum }}</span>
        <van-icon name="share-o" class="icon" />
        <span>{{ item.shareNum }}</span>
      </div>
      <div class="right">
        <van-icon name="user-o" class="icon" />
        <span class="mr">{{ item.publisher }}</span>
        <van-icon name="clock-o" class="icon" />
        <span>{{ item.publishTime && item.publishTime.split(' ')[0] }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon, Image as VanImage } from 'vant'
export default {
  name: 'ListItem',
  components: {
    [Icon.name]: Icon,
    [VanImage.name]: VanImage,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    goContent() {
      const { contentId } = this.item
      this.$router.push(`/content/detail?id=${contentId}`)
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/style/common.less';
.list-item {
  border-radius: 32px;
  box-shadow: 2px 2px 9px 1px rgba(3, 3, 3, 0.05),
    -2px -2px 9px 1px rgba(3, 3, 3, 0.05);
  background-color: #fff;
  padding: 48px;
  margin: 72px 0;
  .info {
    display: flex;
    .left {
      // flex: 1;
      width: 75%;
    }
    .right {
      margin: 0 0 0 24px;
    }
  }
  .pic {
    display: block;
    width: 427px;
    height: 240px;
  }
  .title {
    font-size: 54px;
    // white-space: nowrap;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .des {
    font-size: 40px;
    color: @font-gray-1;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 50px;
  }
  .footer {
    font-size: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: @primary-font-color;
    margin: 20px 0 0;
    .icon {
      margin: 0 14px 0 0;
    }
    .mr {
      margin: 0 40px 0 0;
    }
  }
}
</style>
