import request from '@/utils/request'

// 获取收藏列表
export function getCollect(params) {
  return request({
    url: '/knowledge/collection/mylist',
    method: 'get',
    params
  })
}

// 添加收藏 or 取消收藏
export function collectApi(params) {
  return request({
    url: `/knowledge/collection/operate?contentId=${params}`,
    method: 'post',
  })
}

export function batchDeleteCollect(params) {
  return request({
    url: '/knowledge/collection/cancelBatch',
    method: 'delete',
    params
    
  })
}

// 取消收藏
export function deleteCollect(params) {
  console.log(params);
  return request({
    url: '/knowledge/collection/cancel',
    method: 'delete',
    params:{params}
  })
}