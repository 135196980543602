<template>
  <div class="my">
    <my-header>
      <div slot="left"></div>
    </my-header>
    <router-view></router-view>
  </div>
</template>

<script>
import { Divider } from 'vant'
export default {
  name: 'My',
  data() {
    return {
      title: '个人中心',
    }
  },
  components: {
    [Divider.name]: Divider,
  },

  created() {},

  methods: {
    goFeedBack() {
      this.$router.push('/feedback')
    },
    goMessage() {
      this.$router.push('/message')
    },
    goConfig() {
      this.$router.push('/my/setting')
    },
  },
}
</script>

<style lang="less" scoped>
.my {
  .back {
    position: absolute;
    left: 60px;
  }
  .ml {
    margin-left: 60px;
  }
  .header {
    padding: 64px 64px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .title {
      font-size: 64px;
    }
    .icon {
      height: 60px;
      width: auto;
    }
    .right {
      position: absolute;
      right: 64px;
    }
  }
  .divider {
    margin: 40px 0;
  }
}
</style>
