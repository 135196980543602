<template>
  <div class="container">
    <div class="header">
      <div class="top">
        <img class="icon back" src="~@/assets/image/icon/back.png" alt="icon" @click="$utils.goBack" />
        <span class="name">{{ detail.title }}</span>
      </div>
      <!-- <div class="num">
        发布于消毒供应频道稿文赏析
      </div> -->
    </div>

    <van-divider class="divider" />

    <div class="content">
      <p class="des">
        {{ detail.summary || '暂无简介' }}
      </p>

      <div class="tags">
        <van-tag class="tag" round v-for="(t, i) in tags || []" :key="i" type="primary">{{ t }}</van-tag>
      </div>

      <div class="detail rich" v-if="detail.type == 1" v-html="detail.detail"></div>

      <div class="detail" v-else-if="detail.type == 2">
        <!-- 文件预览 -->
        <div id="pdf" v-if="isPdf">
          <pdf :src="pdfUrl" :page="page" @page-loaded="pageload()"> </pdf>
          <div class="detail-action">
            <van-button color="#ea5514" @click="last" class="detail-action-button" :loading="loading">上一页</van-button>
            <van-button color="#ea5514" @click="next" class="detail-action-button" :loading="loading">下一页</van-button>
          </div>
        </div>
        <iframe v-else :src="fileContenturl" class="detail-frame" frameborder="0" height="100%"></iframe>
      </div>

      <div class="detail" v-else-if="detail.type == 3">
        <!-- 图片内容 -->
        <van-image class="detail-img" lazy-load show-loading v-for="(image, imageIndex) in detail.files" :key="imageIndex"
          :src="image.fileUrl">
        </van-image>
      </div>

      <div class="detail" v-else-if="detail.type == 4">
        <!-- 视频内容 -->
        <!-- <p class="asset-title">
          {{ detail.files[0] ? detail.files[0].fileName : '' }}
        </p> -->
        <video :src="detail.files[0] ? detail.files[0].fileUrl : ''" style="width: 100%" controls></video>
      </div>

      <div class="detail" v-else-if="detail.type == 5">
        <!-- 音频内容 -->
        <div v-for="(audio, audioIndex) in detail.files" :key="audioIndex" class="detail-audio">
          <p class="asset-title">{{ audio.fileName || '' }}</p>
          <audio :src="audio.fileUrl" controls></audio>
        </div>
      </div>

      <div class="footer">
        <div class="left">
          <van-icon name="eye-o" class="icon" />
          <span class="mr">{{ detail.readingNum }}</span>
          <van-icon name="star-o" class="icon" @click="collect()" v-if="detail.collectionStatus == 0" />
          <van-icon name="star" v-else @click="unCollect()" />
          <span class="mr">{{ detail.collectionNum }}</span>
          <van-icon name="share-o" class="icon" />
          <span>{{ detail.shareNum }}</span>
        </div>
        <div class="right">
          <van-icon name="user-o" class="icon" />
          <span class="mr">{{ detail.publisher }}</span>
          <van-icon name="clock-o" class="icon" />
          <span>{{
            detail.updateTime && detail.updateTime.split(' ')[0]
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Base64 } from 'js-base64'
import { Divider, Tag, Icon, Image as VanImage } from 'vant'
import { getDetailById } from '@/api/content'
import { collectApi } from '@/api/my/collect'
import pdf from "vue-pdf";

export default {
  name: 'ContentDetail',
  components: {
    [Icon.name]: Icon,
    [Divider.name]: Divider,
    [Tag.name]: Tag,
    [VanImage.name]: VanImage,
    pdf
  },
  data() {
    return {
      detail: {
        type: 1,
      },
      fileContenturl: '',
      tags: [],
      pdfUrl: null,
      isPdf: false,
      pages: 0, //pdf总页码数
      page: 1, //pdf当前页码数
      url: '',
      loading: false,
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      const { id } = this.$route.query

      if (!id) {
        this.$toast.fail('信息不全，请返回重新操作')
        return
      }

      this.getDetail(id)
    },
    getDetail(id) {
      // 获取详情
      getDetailById(id).then((res) => {
        if (res && res.success) {
          this.detail = res.result
          this.handleDetailData()
        }
      })
    },
    handleDetailData() {
      // 处理详情数据
      const { tag, files } = this.detail
      let tags = tag.split(',')
      if (!tags[0]) {
        tags = []
      }
      this.tags = tags
      if (this.detail.type == 2) {
        const url = files[0] ? files[0].fileUrl : ''
        this.fileContenturl = `${process.env.VUE_APP_PREVIEW_BASE_URL
          }?url=${encodeURIComponent(Base64.encode(url))}`
        if (url.substring(url.length - 3, url.length).toLowerCase() == 'pdf') {
          this.isPdf = true
          this.pdfUrl = url
          let src = pdf.createLoadingTask({
            url: url,
          })
          src.promise.then((pdf) => {
            this.pages = pdf.numPages
          })
        }
      }
    },
    next() {
      if (this.page >= this.pages) {
        return
      }
      this.page += 1
      this.loading = true
    },
    last() {
      if (this.page <= 1) {
        return
      }
      this.page -= 1
      this.loading = true
    },
    pageload() {
      console.log('success');
      setTimeout(() => {
        this.loading = false
      }, 1000);
    },
    collect() {
      collectApi(this.$route.query.id).then((res) => {
        if (res.code == 200) {
          this.$toast.success('添加收藏成功！')
          this.detail.collectionStatus = 1
          this.detail.collectionNum = Number(this.detail.collectionNum) + 1
        }
      })
    },
    unCollect() {
      collectApi(this.$route.query.id).then((res) => {
        if (res.code == 200) {
          this.$toast.success('取消收藏成功！')
          this.detail.collectionStatus = 0
          this.detail.collectionNum -= 1
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/style/common.less';

.container {
  padding: 0 0 100px;
}

.header {
  padding: 64px 64px 0;
}

.top {
  display: flex;
  align-items: center;

  .back {
    width: 32px;
    height: auto;
    margin: 0 110px 0 0;
  }

  .name {
    flex: 1;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    font-size: 64px;
  }
}

.num {
  font-size: 32px;
  margin: 42px 0 0 136px;
  color: @primary-font-color;
}

.content {
  padding: 0 64px;
}

.des {
  color: @font-gray-1;
  font-size: 40px;
  line-height: 60px;
  text-align: justify;
}

.tags {
  margin: 40px 0;

  .tag {
    margin: 30px 40px 0 0;
    padding: 2px 24px;
    background-color: @primary-font-color;
  }
}

.detail {
  width: 100%;
  overflow-x: hidden;

  &-action {
    display: flex;
    justify-content: space-around;
    margin-top: 50px;

    &-button {
      border-radius: 20px;
      height: 120px;
    }
  }
}

.rich {
  /deep/ img {
    max-width: 100% !important;
    height: auto !important;
  }
}

.detail-img,
.detail-audio {
  margin: 24px 0;
}

.detail-img {
  width: 100%;
}

.detail-frame {
  width: 100%;
  height: 66vh;
}

.asset-title {
  font-size: 54px;
  margin: 46px 0;
}

.footer {
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: @primary-font-color;
  margin: 64px 0 0;

  .icon {
    margin: 0 14px 0 0;
  }

  .mr {
    margin: 0 40px 0 0;
  }
}
</style>
