<template>
  <div class="search">
    <img class="icon back" src="~@/assets/image/icon/back.png" alt="icon" @click="$utils.goBack">

    <div class="input-item">
      <van-field
        v-model="keyword"
        label=""
        placeholder="请输入关键词"
        right-icon="search"
        @click-right-icon="search"
      >
      </van-field>
    </div>

    <img class="icon message" src="~@/assets/image/icon/bell-active.png" alt="icon" @click="goMessage">
  </div>
</template>

<script>
export default {
  name: 'Search',
  components: {
  },
  data() {
    return {
      keyword: ''
    }
  },
  methods: {
    goMessage() {
      this.$router.push('/message')
    },
    search() {
      const keyword = this.keyword.trim()
      
      this.$emit('search', keyword)
    }
  }
}
</script>

<style lang="less" scoped>
  @import '~@/assets/style/common.less';
  .search {
    display: flex;
    align-items: center;
    .input-item {
      border-radius: 56px;
      height: 112px;
      display: flex;
      align-items: center;
      border: 1px solid @primary-color;
      margin: 0 76px 0 30px;
      flex: 1;
      /deep/ .van-cell {
        padding: 0 64px;
      }
      /deep/ .van-field__control {
        color: #000;
      }
    }
    .back {
      width: 32px;
      height: auto;
    }
    .message {
      width: 66px;
      height: auto;
    }
  }
</style>