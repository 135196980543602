import request from '@/utils/request'

// 通过主题获取内容列表
export function getContByTopic(params) {
  const { id } = params
  if (!id) {
    return new Promise().resolve(null)
  }
  
  delete params.id

  return request({
    url: `/knowledge/content/${id}/list`,
    method: 'get',
    params
  })
}

// 通过id获取详情
export function getDetailById(id) {
  return request({
    url: '/knowledge/content/id',
    method: 'get',
    params: { id }
  })
}

// 搜索分页列表
export function getContList(params) {
  return request({
    url: '/knowledge/contentSearch/search',
    method: 'get',
    params
  })
}

// 获取搜索关键词
export function contentSearchWords() {
  return request({
    url: '/knowledge/contentSearch/hotKeywords',
    method: 'get',
    params: {}
  })
}
