<template>
  <div class="list catalog">
    <div class="header">
      <img class="icon back" src="~@/assets/image/icon/back.png" alt="icon" @click="$utils.goBack">
      <span class="name">{{detail.name || '目录标题'}}</span>
    </div>

    <van-tabs v-model="activeTab" animated @change="tabChange" class="tabs" line-width="6rem">
      <van-tab title-class="tab-title" v-for="(t, i) in tabs" :title="t.title" :name="t.name" :key="i"></van-tab>
    </van-tabs>

    <list ref="list" :id="id" :type="activeTab" />
  </div>
</template>

<script>
import { Tab, Tabs } from 'vant';
import List from './components/List.vue'
import { getDetailById } from '@/api/catalog'
export default {
  name: 'CatalogList',
  components: {
    List,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs
  },
  data() {
    return {
      activeTab: '1',
      tabs: [
        {
          name: '1',
          title: '最新'
        },
        {
          name: '2',
          title: '最热'
        }
      ],
      detail: {},
      id: ''
    }
  },
  created() {
    this.getCataId()
    this.getDetail()
  },
  methods: {
    tabChange() {
      this.$refs.list.reset()
    },
    handleSearch(keyword) {
      this.$refs.list.load(keyword)
    },
    getCataId() {
      const { id } = this.$route.query

      this.id = id || ''
    },
    getDetail() {
      // 获取详情
      if (!this.id) return
      getDetailById(this.id)
      .then(res => {
        if (res && res.success) {
          this.detail = res.result || {}
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
  @import '~@/assets/style/common.less';
  .list {
    padding: 64px 64px 0;
  }
  .catalog {
    .header {
      display: flex;
      align-items: center;
      margin: 0 0 64px;
      .back {
        width: 32px;
        height: auto;
        margin: 0 110px 0 0;
      }
      .name {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 70px;
      }
    }
    .tabs {
      /deep/ .van-tab {
        flex: none !important;
        margin: 0 0 0 160px;
        &:first-child {
          margin: 0 !important;
        }
      }
      /deep/ .van-tab {
        color: @font-gray-2;
      }
      /deep/ .van-tab--active {
        color: @primary-color !important;
      }
     ::v-deep .van-tab__text--ellipsis{
        overflow: visible;
      }
    }
  }
</style>