import Index from '@/pages/index'
import Login from '@/pages/login'
import Layout from '@/pages/layout'
import Notfound from '@/pages/404'
import Expert from '@/pages/expert'
import My from '@/pages/my/index'
// 消息中心
import Message from '@/pages/message/index'
// 搜索页
import Search from '@/pages/search/index'
// 目录详情
import CatalogDetail from '@/pages/catalog/detail'
// 主题详情
import TopicDetail from '@/pages/topic/detail'
// 内容详情
import ContentDetail from '@/pages/content/detail'

export default [
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/',
        component: Index,
        meta: { allowBack: false }
      },
      {
        path: '/expert',
        component: Expert
      },
      {
        path: '/collect',
        component: () => import('@/pages/collect')
      },
      {
        path: '/my',
        component: My,
        children: [
          { path: '/', component: () => import('@/pages/my/components/index') },  //我的页面
          { path: 'setting', component: () => import('@/pages/my/components/setting') }, // 设置
        ]
      },
      {
        path: '/test/',
        component: () => import('@/pages/test/index'),
      }, {
        path: '/test/start',
        component: () => import('@/pages/test/start'),
      }, {
        path: '/test/wrong',
        component: () => import('@/pages/test/wrong'),
      }, {
        path: '/test/result',
        component: () => import('@/pages/test/result/index')
      },
      {
        path: '/test/analysis',
        component: () => import('@/pages/test/analysis/index')
      },
      {
        path: '/feedback',
        component: () => import('@/pages/feedBack/index')
      },
      {
        path: '/feedback/history',
        component: () => import('@/pages/feedBack/historyFeedBack')
      }, {
        path: '/questionnaire',
        component: () => import('@/pages/questionnaire/index'),
        children: [
          {
            path: 'anonymity',
            component: () => import('@/pages/questionnaire/anonymity/index'),
            children: [
              {
                path: 'main',
                component: () => import('@/pages/questionnaire/anonymity/main')
              },
              {
                path: 'end',
                component: () => import('@/pages/questionnaire/anonymity/end')
              }
            ]
          },
          {
            path: 'realName',
            component: () => import('@/pages/questionnaire/realName/index'),
            children: [
              {
                path: 'main',
                component: () => import('@/pages/questionnaire/realName/main')
              },
              {
                path: 'login',
                component: () => import('@/pages/questionnaire/realName/login')
              },
              {
                path: 'reg',
                component: () => import('@/pages/questionnaire/realName/reg')
              },
              {
                path: 'start',
                component: () => import('@/pages/questionnaire/realName/start')
              },
              {
                path: 'end',
                component: () => import('@/pages/questionnaire/realName/end')
              }
            ]
          }, {
            path: '/',
            component: () => import('@/pages/questionnaire/list.vue')
          }
        ]
      },
      {
        path: '/message',
        component: Message
      },
      {
        path: '/catalog/detail',
        component: CatalogDetail
      },
      {
        path: '/topic/detail',
        component: TopicDetail
      },
      {
        path: '/content/detail',
        component: ContentDetail
      },
      {
        path: '/search',
        component: Search
      }
    ]
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/register',
    component: () => import('@/pages/register')
  },
  {
    path: '/bindPhone',
    component: () => import('@/pages/bindPhone')
  },
  {
    path: '*',
    component: Notfound
  }
]