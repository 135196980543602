<template>
  <div class="layout" :style="{ paddingBottom: footerHidden ? '' : 0 }">
    <router-view />
    <Footer v-show="footerHidden" />
    <img
      v-show="showToTop"
      class="backTop"
      src="~@/assets/image/icon/backTop.png"
      alt="backTop"
      @click="toTop()"
    />
  </div>
</template>

<script>
export default {
  name: 'Layout',
  components: {},
  data() {
    return {
      showToTop: false,
    }
  },
  computed: {
    footerHidden() {
      let path = this.$route.path.split('/')[1]
      if (
        this.$route.path == '/my/test' ||
        this.$route.path == '/test/analysis' ||
        this.$route.path == '/feedback' ||
        path == 'questionnaire'
      ) {
        return false
      } else {
        return true
      }
    },
  },
  mounted() {
    this.bindScroll()
  },
  methods: {
    bindScroll() {
      // 绑定回到顶部
      window.addEventListener('scroll', () => {
        const top = window.document.documentElement.scrollTop || document.body.scrollTop
        if (top > 300) {
          this.showToTop = true
        } else {
          this.showToTop = false
        }
      })
    },
    toTop() {
      // 回到顶部
      window.document.documentElement.scrollTop = 0
      document.body.scrollTop = 0
    },
  },
}
</script>

<style lang="less" scoped>
.layout {
  overflow-x: hidden;
  padding: 0 0 210px 0;
  .backTop {
    position: fixed;
    right: 0;
    bottom: 534px;
    width: 194px;
    height: auto;
    z-index: 999;
  }
}
</style>
