<template>
  <div class="list-container">
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      :offset="5"
      @load="getList"
    > 
      <list-item v-for="(l, i) in list" :key="i" :item="l" />
    </van-list>
  </div>
</template>

<script>
import ListItem from './ListItem'
import { getNewTopicByCata, getHotTopicByCata } from '@/api/topic'
export default {
  name: 'ListContainer',
  components: {
    ListItem,
  },
  data() {
    return {
      loading: false,
      finished: false,
      list: []
    }
  },
  props: {
    type: {
      type: String,
      default: '' // 最新，最热
    },
    id: {
      type: String,
      default: ''
    }
  },
  methods: {
    reset() {
      // 重置
      this.list = []
      this.finished = false
    },
    load(keyword) {
      this.reset()
      this.getList(keyword)
    },
    getList(keyword) {
      const query = {
        id: this.id,
        keyword: keyword || undefined
      }

      this.loading = true
      if (this.type == '1') {
        getNewTopicByCata(query)
        .then(res => {
          if (res && res.success) {
            this.handleListData(res.result)
          }
        })
      } else if (this.type === '2') {
        getHotTopicByCata(query)
        .then(res => {
          if (res && res.success) {
            this.handleListData(res.result)
          }
        })
      }

      // setTimeout(() => {
      //   for (let i = 0; i < 10; i++) {
      //     this.list.push(this.list.length + 1);
      //   }

      //   // 加载状态结束
      //   this.loading = false;

      //   // 数据全部加载完成
      //   if (this.list.length >= 40) {
      //     this.finished = true;
      //   }
      // }, 1000);
    },
    handleListData(data) {
      // 处理列表数据
      this.list = data
      this.finished = true
      this.loading = false
    }
  }
}
</script>

<style scoped lang="less">

</style>