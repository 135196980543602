<template>
  <div class="list-item" @click="openMsg">
    <div class="info">
      <div class="left">
        <p class="title">
          {{item.titile || '暂无标题'}}
        </p>
        <p class="des">
          {{item.msgAbstract || '暂无内容'}}
        </p>
      </div>
    </div>
    <div class="footer">
      <div class="left">
        <van-icon name="user-o" class="icon" />
        <span class="mr">{{item.sender}}</span>
        <van-icon name="clock-o" class="icon" />
        <span class="mr">{{item.sendTime && item.sendTime.split(' ')[0]}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {  Icon } from 'vant';
export default {
  name: 'ListItem',
  components: {
    [Icon.name]: Icon
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    openMsg() {
      // 打开通知
      const { titile, msgAbstract } = this.item

      this.$dialog.alert({
        title: titile,
        message: msgAbstract
      })
    }
  }
}
</script>

<style lang="less" scoped>
  @import '~@/assets/style/common.less';
  .list-item {
    border-radius: 32px;
    box-shadow: 2px 2px 9px 1px rgba(3,3,3,0.05), -2px -2px 9px 1px rgba(3,3,3,0.05);
    background-color: #fff;
    padding: 48px;
    margin: 72px 0;
    .pic {
      display: block;
      width: 100%;
      height: 480px;
      margin: 0 0 24px;
    }
    .title {
      font-size: 54px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0 0 20px;
    }
    .info {
      font-size: 40px;
      color: @font-gray-1;
      overflow:hidden;
      text-overflow:ellipsis;
      display:-webkit-box;
      -webkit-line-clamp:2;
      -webkit-box-orient:vertical;
      line-height: 60px;
    }
    .footer {
      font-size: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: @primary-font-color;
      margin: 20px 0 0;
      .icon {
        margin: 0 14px 0 0;
      }
      .mr {
        margin: 0 40px 0 0;
      }
    }
  }
</style>