<template>
  <div id="app">
    <van-overlay :show="loadingShow">
      <van-loading
        type="spinner"
        vertical
        text-size="60px"
        color="#0094ff"
        style="margin-top: 100%"
      >
      </van-loading>
    </van-overlay>
    <router-view />
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'App',
  computed: {
    ...mapState(['loadingShow']),
  },
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.van-toast {
  width: fit-content;
  padding-bottom: 0px;
}
.van-toast__text {
  white-space: nowrap;
}
</style>
