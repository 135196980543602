import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import cookie from 'js-cookie'
import { userTokenKey } from '@/config/index'
import store from '@/store/index'
Vue.use(VueRouter)

const router = new VueRouter({
  routes,
  mode: 'history'
})

// 监听router push报错
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err)
}

const routerReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
  return routerReplace.call(this, location).catch(err => err)
}

router.beforeEach((to, from, next) => {
  const token = cookie.get(userTokenKey)
  let path = to.path.split('/')
  if (path[1] == 'questionnaire') {
    // document.title = '问卷调查'
    if (path[2] == 'realName') {
      // 未登录状态
      if (!token && path[3] != 'login' && !token && path[3] != 'reg') {
        next({ path: '/questionnaire/realName/login', query: to.query })
      } else {
        next()
      }
    } else {
      next()
    }
  } else {
    document.title = '消供学院'
    // 消供学院
    if (!token && to.path !== '/login' && to.path !== '/bindPhone' && to.path !== '/register') { // 未登录状态下只能访问登录页
      next('/login')
    } else if (token && to.path === '/login') {
      next('/')
    } else {
      next()
    }
  }

})

export { router }