import request from '@/utils/request'

// 通过id获取详情
export function getDetailById(id) {
  return request({
    url: '/knowledge/catalogue/queryById',
    method: 'get',
    params: { id }
  })
}

// 获取列表
export function getList(params) {
  return request({
    url: '/knowledge/catalogue/list',
    method: 'get',
    params
  })
}

// 获取轮播图列表
export function getImgList(params) {
  return request({
    url: '/knowledge/rotation/list?column=itemOrder&order=asc',
    method: 'get',
    params
  })
}
