<template>
  <div class="layout-footer">
    <div class="short-cut" v-for="(s, i) in shortcuts" :key="i" @click="go(s)">
      <img class="icon" :src="activePath === s.path ? s.activeIcon : s.icon" alt="icon">
      <p class="title" :class="{'active': activePath === s.path}">{{s.title}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      shortcuts: [
        {
          title: '首页',
          icon: require('../../assets/image/icon/footer/home.png'),
          activeIcon: require('../../assets/image/icon/footer/home-active.png'),
          path: '/'
        },
        {
          title: '专家',
          icon: require('../../assets/image/icon/footer/ask.png'),
          activeIcon: require('../../assets/image/icon/footer/ask-active.png'),
          path: '/expert'
        },
        {
          title: '收藏',
          icon: require('../../assets/image/icon/footer/star.png'),
          activeIcon: require('../../assets/image/icon/footer/star-active.png'),
          path: '/collect'
        },
        {
          title: '我的',
          icon: require('../../assets/image/icon/footer/my.png'),
          activeIcon: require('../../assets/image/icon/footer/my-active.png'),
          path: '/my'
        }
      ]
    }
  },
  computed: {
    activePath() {
      const { path } = this.$route
      
      return path
    }
  },
  methods: {
    go(item) {
      const disables = ['/expert', '']
      const { path } = item
      if (disables.includes(path)) {
        this.$toast.fail('暂未开放，敬请期待')
        return
      }

      this.$router.push(path)
    },
  }
}
</script>

<style lang="less" scoped>
  @import '~@/assets/style/common.less';
  .layout-footer {
    width: 100%;
    height: 210px;
    position: fixed;
    z-index: 999;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 150px;
    box-shadow: 0px -3px 9px 1px rgba(3, 3, 3, 0.1);
    background-color: #fff;
    .short-cut {
      display: flex;
      flex-direction: column;
      align-items: center;
      .icon {
        display: block;
        width: auto;
        height: 64px;
        margin-bottom: 30px;
      }
      .title {
        font-size: 40px;
        color: @primary-font-color;
        &.active {
          color: #000;
        }
      }
    }
  }
</style>