import Vue from 'vue'
import Vuex from 'vuex'
import { router } from '@/router/index'
import store from '@/store/index'
import utils from '@/utils/utils'
import '@/utils/rem.js'
import App from '@/App.vue'
import { Toast, Lazyload, Dialog, Tab, Tabs, Swipe, SwipeItem, Divider, Image as VanImage, List, Icon, Cell, CellGroup, Button, Overlay, Field, Switch, PullRefresh, Uploader, Loading, Progress, AddressEdit, Area, Popup } from 'vant';
import cookie from 'js-cookie'
import '@/assets/style/reset.less';
import '@/assets/style/common.less';
import componentsList from '@/components/index';

Vue.use(componentsList)
Vue.use(Vuex)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Divider)
Vue.use(Toast)
Vue.use(Lazyload)
Vue.use(Dialog)
Vue.use(VanImage)
Vue.use(List)
Vue.use(Icon)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(Button)
Vue.use(Overlay)
Vue.use(Field)
Vue.use(Switch)
Vue.use(PullRefresh)
Vue.use(Uploader)
Vue.use(Loading)
Vue.use(Progress)
Vue.use(AddressEdit)
Vue.use(Area)
Vue.use(Popup)

Vue.prototype.$utils = utils
Vue.prototype.$toast = Toast
Vue.prototype.$dialog = Dialog
Vue.prototype.$cookie = cookie

//清除vuex缓存中的pathFull属性
// console.log(localStorage.getItem('vuex'));
// let obj = JSON.parse(localStorage.getItem('vuex'))
// console.log(obj);
// if (obj&&obj.pathFull) {
//   obj.pathFull = ''
// }
// localStorage.setItem('vuex',JSON.stringify(obj))

Vue.config.productionTip = false
// const store = new Vuex.Store(storeConfig)

new Vue({
  el: '#app',
  store,
  router,
  render: h => h(App)
})
