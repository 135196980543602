import axios from 'axios'
import cookie from 'js-cookie'
import vue from 'vue'
import { userTokenKey } from '@/config/index.js'
import utils from '@/utils/utils'

axios.interceptors.request.use(
  config => {
    const token = cookie.get(userTokenKey);
    if (token) {
      config.headers['X-Access-Token'] = `${token}`
    }

    return config
  },
  error => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    const { status } = error.response
    if (status === 401) {
      // utils.logout()
    } else if (status === 500) {
      vue.prototype.$toast.fail('请求失败')
    }
    return Promise.reject(error)
  }
)

export default ({method, url, params = {}}) => {
  return new Promise((resolve, reject) => {
    const apiBaseUrl = process.env.VUE_APP_API_BASE_URL
    let query = params
    if (method === 'get') {
      query = { params }
    }

    axios[method](`${apiBaseUrl}${url}`, query)
    .then((res) => {
      if (res) {
        const data = res.data
        if (!data.success) {
          vue.prototype.$toast.fail(data.message)
        }
        
        resolve(data)
      }
    })
    .catch((error) => {
      reject(error)
    })
  })
}