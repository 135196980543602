import request from '@/utils/request'

// 发送验证码
export function sendSMS(params) {
  return request({
    url: `/sys/sms`,
    method: 'post',
    params
  })
}

// 手机 + 验证码登录
export function loginByPhone(params) {
  return request({
    url: `/sys/phoneLogin`,
    method: 'post',
    params
  })
}

export function registerCode(params) {
  return request({
    url: '/sys/user/phoneRegist',
    method: 'post',
    params
  })
}


// 获取微信处理token
export function wxLoginCode(params) {
  return request({
    url: '/sys/weixinLogin',
    method: 'post',
    params
  })
}

// 用户注册同时填写信息
export function registerInfo(params) {
  return request({
    url: `/sys/user/register`,
    method: 'post',
    params
  })
}

// 修改用户信息
export function editInfo(params) {
  return request({
    url: `/sys/user/edit`,
    method: 'post',
    params
  })
}

//提交用户信息收集清单
export function addAnonymityUser(params) {
  return request({
    url: `/sys/user/addQueInfoCollectValue`,
    method: 'post',
    params
  })
}