import request from '@/utils/request'

// 获取消息列表
export function getMessageList(query) {
  return request({
    url: '/sys/sysAnnouncementSend/getMyAnnouncementSend',
    method: 'get',
    params: query
  })
}

// 全部已读
export function readAllMessage() {
  return request({
    url: '/sys/sysAnnouncementSend/readAll',
    method: 'put',
    params: {}
  })
}