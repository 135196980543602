<template>
  <div class="topic">
    <div class="header">
      <div class="bar">
        <img class="icon back" src="~@/assets/image/icon/back.png" alt="icon" @click="$utils.goBack">
        <span class="name">{{detail.name || '主题标题'}}</span>
      </div>
      <div class="num">
        内容数量:
        <span>{{total || 0}}</span>
      </div>
    </div>

    <van-divider class="divider" />

    <div class="list">
      <p class="des">
        {{detail.des || '简介'}}
      </p>

      <list :id="id" @getTotal="getTotal" />
    </div>
  </div>
</template>

<script>
import { Divider } from 'vant';
import List from './components/List'
import { getDetailById } from '@/api/topic'
export default {
  name: 'TopicDetail',
  components: {
    [Divider.name]: Divider,
    List
  },
  data() {
    return {
      id: 0,
      total: 0,
      detail: {}
    }
  },
  created() {
    this.getTopicId()
    this.getDetail()
  },
  methods: {
    getTopicId() {
      const { id } = this.$route.query

      this.id = id || ''
    },
    getDetail() {
      // 获取详情
      if (!this.id) return
      getDetailById(this.id)
      .then(res => {
        if (res && res.success) {
          if (res.result) {
            this.detail = res.result
          } else {
            this.$toast.fail('暂无主题内容')
          }
        }
      })
    },
    getTotal(total) {
      this.total = total
    }
  }
}
</script>

<style lang="less" scoped>
  @import '~@/assets/style/common.less';
  .header {
    padding: 64px 64px 0;
  }
  .list {
    padding: 0 64px;
  }
  .bar {
    display: flex;
    align-items: center;
    .back {
      width: 32px;
      height: auto;
      margin: 0 110px 0 0;
    }
    .name {
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 64px;
    }
  }
  .num {
    font-size: 32px;
    margin: 3px 0 0 136px;
    color: @primary-font-color;
  }
  .divider {
    margin: 40px 0;
  }
  .des {
    color: @font-gray-1;
    font-size: 40px;
    line-height: 60px;
    text-align: justify;
  }
</style>