<template>
  <div class="list-container">
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      :offset="50"
      :immediate-check="false"
      @load="getList"
    > 
      <list-item v-for="(l, i) in list" :key="i" :item="l" />
    </van-list>
  </div>
</template>

<script>
import { List } from 'vant';
import ListItem from './ListItem';
import { getContList } from '@/api/content'
export default {
  name: 'ListContainer',
  components: {
    ListItem,
    [List.name]: List
  },
  data() {
    return {
      loading: false,
      finished: false,
      queryParams: {
        pageNo: 1,
        pageSize: 10
      },
      list: []
    }
  },
  methods: {
    reset() {
      // 重置
      this.queryParams.pageNo = 1
      this.list = []
      this.finished = false
    },
    load(keyword) {
      this.reset()
      this.getList(keyword)
    },
    getList(keyword) {
      // 获取数据
      const { pageNo, pageSize } = this.queryParams

      const query = {
        keyword: keyword,
        pageNo,
        pageSize
      }

      this.loading = true
      getContList(query)
      .then(res => {
        if (res && res.success) {
          const { records, total } = res.result
          this.loading = false
          this.list = this.list.concat(records)
          this.queryParams.pageNo += 1
          if (this.list.length >= total) {
            this.finished = true
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="less">

</style>