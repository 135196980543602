import request from '@/utils/request'

// 通过目录获取最新内容
export function getNewTopicByCata(params) {
  return request({
    url: `/knowledge/theme/getTheNewByCatId`,
    method: 'get',
    params
  })
}

// 通过目录获取最热内容
export function getHotTopicByCata(params) {
  return request({
    url: `/knowledge/theme/getHotTheByCatId`,
    method: 'get',
    params
  })
}

// 通过id获取详情
export function getDetailById(id) {
  return request({
    url: '/knowledge/theme/queryById',
    method: 'get',
    params: { id }
  })
}

// 获取主题列表
// export function getTopicList(params) {
//   return request({
//     url: '/knowledge/theme/list',
//     method: 'get',
//     params
//   })
// }


//新主题列表
export function getTopicList(params) {
  return request({
    url: '/knowledge/homeManage/list?column=itemOrder&order=asc',
    method: 'get',
    params
  })
}