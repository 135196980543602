<template>
  <div class="login">
    <img src="~@/assets/image/logo-xgxy.png" alt="" class="logo" />
    <div class="wrapper">
      <div class="input-item mb-btn">
        <van-field
          v-model="phone"
          label=""
          placeholder="请输入手机号"
          maxlength="11"
        >
        </van-field>
      </div>

      <div class="input-item mb-btn-2">
        <van-field
          v-model="verifyCode"
          label=""
          maxlength="6"
          placeholder="请输入验证码"
        >
          <template #button>
            <span
              class="send-text"
              @click="sendVerify()"
              :class="{ 'disable-text': countDown.status === 'run' }"
            >
              {{
                countDown.status === 'run'
                  ? `${countDown.duration}s后重发`
                  : '发送验证码'
              }}
            </span>
          </template>
        </van-field>
      </div>

      <van-button class="big-btn" @click="login()">登录</van-button>
      <van-button class="reg" @click="register()">注册</van-button>
    </div>
    <div class="sub-title">其他登录方式</div>
    <div class="third-channels">
      <img @click="wxlogin()" src="~@/assets/image/icon/weixin.png" alt="" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { sendSMS, loginByPhone, wxLoginCode } from '@/api/login'
import { userTokenKey } from '@/config/index'
export default {
  name: 'Login',
  components: {},
  data() {
    return {
      phone: '',
      verifyCode: '',
      phoneReg: new RegExp(
        /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/
      ),
      appid: 'wx43bfcb3082f90fb5',
      // url: 'http://xgxy.68cssd.com/login',  //正式环境
      // url:'http://knowledge-dev.honjoinfo.com/login',  //测试环境
      url: process.env.VUE_APP_WX_LOGIN_URL,
      countDown: {
        timer: null,
        duration: 60,
        status: 'stop',
        start: () => {
          this.countDown.timer =
            this.countDown.timer ||
            setInterval(() => {
              this.countDown.status = 'run'
              this.countDown.duration -= 1
              if (this.countDown.duration <= 0) {
                this.countDown.stop()
              }
            }, 1000)
        },
        stop: () => {
          clearInterval(this.countDown.timer)
          this.countDown.timer = null
          this.countDown.status = 'stop'
          this.countDown.duration = 60
        },
      },
    }
  },
  created() {
    if (
      window.location.href.split('?')[1] &&
      window.location.href.split('?')[1].split('=')[0] == 'code'
    ) {
      let query = decodeURIComponent(window.location.href.split('?')[1])
      let code = query.split('&')[0].split('=')[1]
      this.setLoading()
      wxLoginCode({ code: code }).then((res) => {
        this.setLoading()
        if (res.result.access_token) {
          this.$router.replace({ path: '/bindPhone', query: { info: res } })
        } else {
          const data = res.result
          this.$toast.success('登录成功')
          const { token, userInfo } = data

          this.$cookie.set(userTokenKey, token, { expires: 1 })
          this.setUser(userInfo)

          this.$router.replace('/')
        }
      })
    }
  },
  mounted() {},
  methods: {
    ...mapActions(['setUser', 'setLoading']),
    sendVerify() {
      // 发送验证码
      if (this.countDown.timeout) {
        return
      }
      this.countDown.timeout = true
      if (this.countDown.status === 'run') return
      if (!this.phone) {
        this.$toast.fail('请输入手机号')
        return
      } else if (!this.phoneReg.test(this.phone)) {
        this.$toast.fail('请输入正确的手机号')
        return
      }

      const params = {
        mobile: this.phone,
        smsmode: '0',
      }

      sendSMS(params).then((res) => {
        this.countDown.timeout = false
        if (res && res.success) {
          this.$toast.success('验证码发送成功，请查收')
          this.startCountDown()
        }
      })
    },
    startCountDown() {
      // 开始倒计时
      this.countDown.start()
    },
    login() {
      // 登录
      if (!this.phone) {
        this.$toast.fail('请输入手机号')
        return
      } else if (!this.phoneReg.test(this.phone)) {
        this.$toast.fail('请输入正确的手机号')
        return
      } else if (!this.verifyCode) {
        this.$toast.fail('请输入验证码')
        return
      }

      const params = {
        captcha: this.verifyCode,
        mobile: this.phone,
        remember_me: true,
      }

      loginByPhone(params).then((res) => {
        if (res && res.success) {
          const data = res.result
          this.$toast.success('登录成功')
          const { token, userInfo } = data

          this.$cookie.set(userTokenKey, token, { expires: 1 })
          this.setUser(userInfo)
          this.$router.replace('/')
        }
      })
    },
    register() {
      this.$router.push({
        path: '/register',
      })
    },
    wxlogin() {
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.appid}&redirect_uri=${this.url}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/style/common.less';
.login {
  padding: 0 0 200px;
  .logo {
    width: 360px;
    height: auto;
    display: block;
    margin: 150px auto 80px;
  }
  .wrapper {
    border-radius: 56px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(3, 3, 3, 0.05);
    padding: 60px 56px 120px;
    margin: 0 62px;
  }
  .send-text {
    color: @primary-color;
  }
  .disable-text {
    color: @primary-font-color;
  }
  .mb-btn {
    margin-bottom: 56px;
  }
  .mb-btn-2 {
    margin-bottom: 112px;
  }
  .big-btn {
    width: 100%;
    color: #fff;
    font-size: 56px;
    height: 162px;
    border-radius: 80px;
    background: linear-gradient(
      to right,
      rgba(255, 137, 28, 1),
      rgba(255, 85, 8, 1)
    );
  }
  .reg {
    width: 100%;
    color: black;
    font-size: 56px;
    height: 162px;
    border-radius: 80px;
    background-color: white !important;
    border: rgba(255, 85, 8, 1) 2px solid;
    margin-top: 60px;
  }
  .input-item {
    background-color: #f7f7fa;
    border-radius: 80px;
    height: 162px;
    padding: 0 20px;
    display: flex;
    align-items: center;
  }
  .sub-title {
    font-size: 48px;
    color: @primary-font-color;
    margin: 50px 0 50px;
    text-align: center;
  }
  .third-channels {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 160px;
      height: auto;
    }
  }
}
</style>
