<template>
  <div class="list-container">
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      :immediate-check="false"
      :offset="5"
      @load="getList"
    > 
      <list-item v-for="(l, i) in list" :key="i" :item="l" />
    </van-list>
  </div>
</template>

<script>
import { List } from 'vant';
import ListItem from './ListItem'
import { getMessageList } from '@/api/message'
export default {
  name: 'ListContainer',
  components: {
    ListItem,
    [List.name]: List
  },
  data() {
    return {
      loading: false,
      finished: false,
      list: [],
      queryParams: {
        pageNo: 1,
        pageSize: 10
      }
    }
  },
  props: {
    type: {
      type: String,
      default: '' // 全部，未读，已读
    }
  },
  watch: {
    type() {
      this.load()
    }
  },
  methods: {
    reset() {
      // 重置
      this.list = []
      this.queryParams.pageNo = 1
      this.finished = false
    },
    load() {
      this.reset()
      this.getList()
    },
    getList() {
      // 获取列表
      const { pageNo, pageSize } = this.queryParams
      const query = {
        pageNo,
        pageSize,
        readFlag: this.type === '-1' ? undefined : this.type
      }

      this.loading = true
      getMessageList(query)
      .then(res => {
        if (res && res.success) {
          this.loading = false
          const { records, total } = res.result
          this.list = this.list.concat(records)
          this.queryParams.pageNo += 1

          if (this.list.length >= total) {
            this.finished = true
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="less">

</style>