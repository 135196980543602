<template>
  <div class="list-item" @click="goTopic">
    <van-image
      class="pic"
      lazy-load
      show-loading
      :src="item.imageUrl"
      fit="cover"
    >
    </van-image>
    <p class="title">
      {{item.name}}
    </p>
    <p class="info">
      {{item.des}}
    </p>
    <div class="footer">
      <div class="left">
        <van-icon name="eye-o" class="icon" size="3.5rem" style="vertical-align:top"/>
        <span>{{item.num}}</span>
      </div>
      <div class="right">
        <!-- <van-icon name="user-o" class="icon" />
        <span class="mr">{{item.createBy}}</span> -->
        <van-icon name="clock-o" class="icon" />
        {{item.updateTime}}
        <!-- <span>{{item.createTime && item.createTime.split(' ')[0]}}</span> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListItem',
  components: {},
  props: {
    item: {
      type: Object,
      default: ()=> {}
    }
  },
  methods: {
    goTopic() {
      const { id } = this.item
      this.$router.push(`/topic/detail?id=${id}`)
    }
  }
}
</script>

<style lang="less" scoped>
  @import '~@/assets/style/common.less';
  .list-item {
    border-radius: 32px;
    box-shadow: 2px 2px 9px 1px rgba(3,3,3,0.05), -2px -2px 9px 1px rgba(3,3,3,0.05);
    background-color: #fff;
    padding: 48px;
    margin: 72px 0;
    .pic {
      display: block;
      width: 100%;
      height: 480px;
      margin: 0 0 24px;
    }
    .title {
      font-size: 54px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0 0 20px;
    }
    .info {
      font-size: 40px;
      color: @font-gray-1;
      overflow:hidden;
      text-overflow:ellipsis;
      display:-webkit-box;
      -webkit-line-clamp:2;
      -webkit-box-orient:vertical;
      margin-top: -4px;
      line-height: 60px;
    }
    .footer {
      font-size: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: @primary-font-color;
      margin: 5px 0 0;
      .icon {
        margin: -1px 14px 0 0;
      }
      .mr {
        margin: 0 40px 0 0;
      }
    }
  }
</style>