const sha1 = require('sha1')
import script from './script.js'
import { getWXTicket } from '@/api/wx'
import { weixinJsSrc, wxAppId } from '@/config/index'

export default {
  loadWeixinJs() {
    // 加载微信js sdk
    if (window.wx) {
      return Promise.resolve(true)
    }

    return script(weixinJsSrc)
  },
  getWeixinTicket() {
    return getWXTicket()
  },
  getRandomString() {
    // 获取随机数
    return Math.random().toString(36).substring(2)
  },
  wxSDKConfig({ apis = [] }) {
    // 微信sdk配置
    return new Promise( async (resolve) => {
      let ticket = ''
      await Promise.all([this.getWeixinTicket(), this.loadWeixinJs()])
      .then(res => {
        if (res[0]) {
          const { result } = res[0]
          ticket = result.ticket
        }
      })

      if (!ticket) {
        resolve(false)

        return
      }
      
      const randomStr = this.getRandomString()
      const time = Date.parse(new Date()) / 1000
      const url = window.location.href.split('#')[0]
      const strArr = [
        `jsapi_ticket=${ticket}`,
        `noncestr=${randomStr}`,
        `timestamp=${time}`,
        `url=${url}`
      ]
      const str = strArr.join('&')
      const signature = sha1(str)


      window.wx.config({
        debug: process.env.NODE_ENV === 'development',
        appId: wxAppId,
        timestamp: time,
        nonceStr: randomStr,
        signature,
        jsApiList: apis
      })

      wx.checkJsApi({
        jsApiList: apis,
        success: function(res) {
          console.log('checkWXJs', res)
        }
      });

      wx.ready(function(){
        console.log('微信sdk配置ready')
        resolve(true)
      })

      wx.error(function(res){
        resolve(false)

        console.log(`微信配置出错:`, res)
      })
    })
  },
  wxShare(shareInfo) {
    // 调用sdk配置分享
    const {
      title,
      desc,
      link,
      imgUrl
    } = shareInfo

    window.wx.updateAppMessageShareData({ 
      title,
      desc,
      link,
      imgUrl,
      success: function () {
        console.log('分享设置成功（新）')
      }
    })

    window.wx.onMenuShareAppMessage({
      title,
      desc,
      link,
      imgUrl,
      success: function () {
        console.log('分享设置成功（旧）')
      }
    })
  }
}