<template>
  <div class="message">
    <div class="header">
      <div class="left">
        <img class="icon back" src="~@/assets/image/icon/back.png" alt="icon" @click="$utils.goBack">
        <span class="name">消息通知</span>
      </div>
      
      <div class="read-all" @click="handleReadAll">
        <img src="~@/assets/image/icon/sign.png" alt="icon">
        <span>全部已读</span>
      </div>
    </div>

    <van-tabs v-model="activeTab" animated class="tabs" line-width="6rem">
      <van-tab title-class="tab-title" v-for="(t, i) in tabs" :title="t.title" :name="t.name" :key="i"></van-tab>
    </van-tabs>

    <list ref="list" :type="activeTab" />
  </div>
</template>

<script>
import { Tab, Tabs } from 'vant';
import List from './components/List.vue'
import { readAllMessage } from '@/api/message'
export default {
  name: 'Message',
  components: {
    List,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs
  },
  data() {
    return {
      activeTab: '-1',
      tabs: [
        {
          name: '-1',
          title: '全部'
        },
        {
          name: '0',
          title: '未读'
        },
        {
          name: '1',
          title: '已读'
        }
      ],
    }
  },
  mounted() {
    this.$refs.list.load()
  },
  methods: {
    handleReadAll() {
      this.$dialog.confirm({
        title: '确定全部标记已读吗？',
      }).then(() => {
        readAllMessage()
        .then(res => {
          if (res && res.success) {
            this.$toast.success('操作成功')

            this.$refs.list.load()
          }
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .message {
    padding: 64px 64px 0;
    .header {
      display: flex;
      align-items: center;
      margin: 0 0 64px;
      justify-content: space-between;
      .back {
        width: 32px;
        height: auto;
        margin: 0 110px 0 0;
      }
      .name {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 70px;
      }
    }
    .read-all {
      font-size: 48px;
      color: @font-gray-2;
      z-index: 5;
      img {
        width: 56px;
        height: auto;
        margin: 0 14px 0 0 ;
      }
    }
    .tabs {
      /deep/ .van-tab {
        flex: none !important;
        margin: 0 0 0 160px;
        &:first-child {
          margin: 0 !important;
        }
      }
      /deep/ .van-tab {
        color: @font-gray-2;
      }
      /deep/ .van-tab--active {
        color: @primary-color !important;
      }
      ::v-deep .van-tab__text--ellipsis{
        overflow: visible;
      }
    }

  }
</style>