import cookie from 'js-cookie'
import { router } from '@/router/index'
import { userTokenKey } from '@/config/index'
export default {
  logout() {
    // 登出
    cookie.remove(userTokenKey)
    router.replace('/login')
  },
  goBack() {
    // 返回上一页
    router.back()
  },
  // 将时长转换为字符串
  formatTime (total) {
    // 转换时间戳为时间字符串，传入单位为秒
    const sPerMinute = 60
    const sPerHour = 60*60
    const sPerDay = 24*60*60
    const days = total/sPerDay > 0 ? parseInt(total/sPerDay) : 0
    const hours = (total%sPerDay)/sPerHour > 0 ? parseInt((total%sPerDay)/sPerHour) : 0
    const minites = (total%sPerDay)%sPerHour > sPerMinute ? parseInt(((total%sPerDay)%sPerHour)/sPerMinute) : 0
    const seconds = total%sPerDay%sPerHour%sPerMinute
    const dayStr = days ? `${days}天` : ''
    const hourStr = hours ? `${hours}小时` : ''
    const miniStr = minites ? `${minites}分钟` : ''
    const secStr = seconds ? `${seconds}秒` : ''
  
    let fakeMin
    if (dayStr == 0 && hourStr == 0 && miniStr == 0) {
      fakeMin = '1分钟'
    }
    return {
      'd': days,
      'h': hours,
      'm': minites,
      's': seconds,
      'str': `${dayStr}${hourStr}${miniStr}${secStr}`,
      strMin: `${dayStr}${fakeMin?fakeMin:hourStr}${miniStr}`,
    }
  },
  // 判断微信环境
  isWeixin() {
    return /MicroMessenger/i.test(window.navigator.userAgent);
  }
}