<template>
  <div class="index">
    <div class="top-bar">
      <!-- 顶部搜索栏 -->
      <div class="input-item">
        <van-field
          v-model="keyword"
          label=""
          placeholder="请输入关键词"
          right-icon="search"
          @click-right-icon="goSearch"
        >
        </van-field>
      </div>

      <img
        class="icon"
        src="~@/assets/image/icon/bell.png"
        alt="icon"
        @click="goMessage"
      />
      <img
        class="icon"
        src="~@/assets/image/icon/flcz.png"
        alt="icon"
        @click="editIcon"
      />

      <!-- <img class="icon" src="~@/assets/image/icon/config.png" alt="icon" @click="goConfig"> -->
    </div>
    <!-- banner -->
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="#000000">
      <van-swipe-item v-for="(item, index) in imgList" :key="index">
        <img
          :src="item.imageUrl"
          class="banner"
          @click="goBanner(item.conUrl)"
        />
      </van-swipe-item>
    </van-swipe>

    <!-- <van-image
      class="banner"
      lazy-load
      src="https://img01.yzcdn.cn/vant/cat.jpeg"
    >
    </van-image> -->
    <!-- 分类 -->
    <div class="cates">
      <div
        class="cate-item"
        v-for="(c, i) in catalogs"
        :key="i"
        @click="goCata(c)"
      >
        <van-image class="icon" show-loading lazy-load :src="c.imageUrl">
        </van-image>
        <p class="title">{{ c.name }}</p>
      </div>
    </div>
    <!-- 入口 -->
    <img
      v-for="(e, i) in enters"
      :key="i"
      :src="e.img"
      alt="img"
      class="enter-banner"
      @click="goLesson(e.path)"
    />
    <!-- 专题 -->
    <div class="topics">
      <div class="topic" v-for="(t, i) in topics" :key="i" @click="goTopic(t)">
        <van-image
          class="topic-banner"
          lazy-load
          show-loading
          :src="t.imageUrl"
        >
        </van-image>
        <p class="topic-title">{{ t.itemTitle }}</p>
        <p class="topic-remark">{{ t.remark }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { getTopicList } from '@/api/topic'
import { getList, getImgList } from '@/api/catalog'
import wx from '@/utils/wx'
export default {
  name: 'Index',
  components: {},
  data() {
    return {
      keyword: '',
      catalogs: [],
      catalogMore: {
        name: '更多',
        imageUrl: require('../assets/image/icon/catelogs/more.png'),
      },
      enters: [
        {
          img: require('../assets/image/kckh.jpg'),
          path: 'https://shop127591181.m.youzan.com/v2/showcase/homepage?alias=MhL86lL0cU',
        },
        {
          img: require('../assets/image/jxkc.jpg'),
          path: 'https://shop41970446.youzan.com/v2/showcase/homepage?alias=tLNA3vo29F',
        },
      ],
      topics: [],
      getImgList,
      imgList: [],
    }
  },
  created() {
    this.getCatalogs()
    this.getTopics()
    this.getImgList().then((res) => {
      this.imgList = res.result.records
    })

    this.configWXShare()
  },
  methods: {
    async configWXShare() {
      // 配置wx sdk
      const configed = await wx.wxSDKConfig({
        apis: ['updateAppMessageShareData', 'onMenuShareAppMessage'],
      })

      if (configed) {
        const url = window.location.href
        const shareInfo = {
          title: '消供学园',
          desc: '红柚消毒供应知识库',
          link: url,
          imgUrl:
            'http://knowledge-dev.honjoinfo.com/img/logo-xgxy.e49ab578.png',
        }

        wx.wxShare(shareInfo)
      }
    },
    getCatalogs() {
      // 获取目录
      const query = {
        pageNo: 1,
        pageSize: 7,
        online: 1,
      }
      getList(query).then((res) => {
        if (res && res.success) {
          const { records } = res.result
          this.catalogs = records || []
        }

        this.catalogs.push(this.catalogMore)
      })
    },
    getTopics() {
      // 获取主题
      const query = {
        pageNo: 1,
        pageSize: 1000,
        online: 1,
      }

      getTopicList(query).then((res) => {
        if (res && res.success) {
          const { records } = res.result
          records.forEach((i) => {
            i.createTime = i.createTime.split(' ')[0] || ''
          })

          this.topics = records
        }
      })
    },
    goCata(item) {
      // 跳转分类
      const { id, webUrl } = item
      if (!id) {
        this.$toast.fail('暂未开放，敬请期待')
        return
      }
      if (webUrl) {
        window.open(webUrl)
      } else {
        this.$router.push(`/catalog/detail?id=${id}`)
      }
    },
    goBanner(url) {
      if (url) window.open(url)
    },
    goMessage() {
      // 跳转消息
      this.$router.push('/message')
    },
    // 编辑首页
    editIcon() {
      console.log('hello world')
    },
    goConfig() {
      // 跳转设置
      this.$router.push('/my')
    },
    goTopic(t) {
      // 跳转主题
      const { itemId } = t
      if (t.webUrl) {
        window.open(t.webUrl)
      } else {
        this.$router.push(`/topic/detail?id=${itemId}`)
      }
    },
    goLesson(item) {
      window.open(item)
    },
    goSearch() {
      const s = this.keyword.trim()
      if (!s) {
        this.$toast.fail('请先输入要搜索的内容')
        return
      }

      this.$router.push(`/search?s=${s}`)
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/style/common.less';
.index {
  width: 100%;
  height: auto;
  padding: 176px 50px 60px;
  background: url(~@/assets/image/index-bg.png);
  background-size: 100% auto;
  background-position: top;
  background-repeat: no-repeat;
  .bg {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 0;
  }
  .input-item {
    border-radius: 56px;
    height: 112px;
    background-color: #fff;
    display: flex;
    align-items: center;
    /deep/ .van-cell {
      padding: 0 64px;
    }
  }
  .top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    .input-item {
      flex: 1;
    }
    .icon {
      height: 80px;
      margin: 0 0 0 72px;
    }
  }
  ::v-deep .van-swipe-item {
    img {
      max-height: 627px;
    }
  }
  .banner {
    width: 100%;
    display: block;
    height: auto;
    margin: 10px 0 58px;
    border-radius: 32px;
  }
  .cates {
    display: flex;
    flex-wrap: wrap;
    .cate-item {
      margin: 28px 60px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 14%;
      .icon {
        width: 176px;
        height: 176px;
        margin-bottom: 32px;
        border-radius: 100%;
        overflow: hidden;
      }
      .title {
        font-size: 40px;
      }
    }
  }
  .enter-banner {
    width: 100%;
    height: auto;
    display: block;
    margin-top: 46px;
    border-radius: 16px;
  }
  .topics {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 27px;
    .topic {
      margin-top: 45px;
      width: 48%;
      border-radius: 16px;
      box-shadow: 2px 2px 9px 1px rgba(3, 3, 3, 0.05),
        -2px -2px 9px 1px rgba(3, 3, 3, 0.05);
      min-height: 519px;
      overflow: hidden;
      .topic-banner {
        width: 100%;
        height: 347px;
        display: block;
        // border-radius: 16px;
        overflow: hidden;
      }
      .topic-title {
        font-size: 50px;
        font-weight: 400;
        color: #000000;
        margin-top: 28px;
        margin-left: 32px;
      }
      .topic-remark {
        font-size: 40px;
        font-weight: 400;
        color: #666666;
        line-height: 60px;
        margin-top: 15px;
        margin-left: 32px;
        margin-bottom: 40px;
      }
    }
  }
}
::v-deep .van-swipe__indicators {
  bottom: 100px;
}
</style>
