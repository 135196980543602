<template>
  <div class="list-item" @click="goContent">
    <div class="info">
      <div class="left">
        <p class="title">
          {{ item.title }}
        </p>
        <p class="des">
          {{ item.summary || '暂无描述' }}
        </p>
      </div>
      <div class="right">
        <van-image class="pic" lazy-load show-loading :src="item.cover">
        </van-image>
      </div>
    </div>
    <div class="footer">
      <div class="left">
        <van-icon
          name="eye-o"
          class="icon"
          size="3.2rem"
          style="vertical-align: top"
        />
        <span class="mr">{{ item.readingNum }}</span>
        <van-icon
          name="star-o"
          class="icon"
          v-if="item.collectionStatus == 0"
        />
        <van-icon name="star" v-else />
        <span class="mr">{{ item.collectionNum }}</span>
        <van-icon name="share-o" class="icon" />
        <span>{{ item.shareNum }}</span>
      </div>
      <div class="right">
        <van-icon name="user-o" class="icon" />
        <span class="mr">{{ item.publisher }}</span>
        <van-icon name="clock-o" class="icon" />
        <span>{{ item.updateTime && item.updateTime.split(' ')[0] }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon, Image as VanImage } from 'vant'
export default {
  name: 'ListItem',
  data() {
    return {
      url: '',
    }
  },
  components: {
    [Icon.name]: Icon,
    [VanImage.name]: VanImage,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    // this.getVideo(this.item.fileUrl)
  },
  methods: {
    goContent() {
      const { id, webUrl } = this.item
      if (webUrl) {
        window.open(webUrl)
        return
      }
      this.$router.push(`/content/detail?id=${id}`)
    },
    getVideo(value) {
      const that = this
      const video = document.createElement('video') // 创建video
      video.setAttribute('crossOrigin', 'anonymous') // 处理跨域
      video.setAttribute('src', value) // 给video添加属性
      video.setAttribute('width', '427')
      video.setAttribute('height', '240')
      video.setAttribute('preload', 'auto') // 当页面加载后载入整个音频
      video.currentTime = 50
      video.addEventListener('loadeddata', () => {
        // 当前帧数据加载完成，下一帧数据未记载事件
        const canvas = document.createElement('canvas'),
          width = video.width, // canvas的尺寸和图片一样
          height = video.height
        canvas.width = width
        canvas.height = height
        const ctx = canvas.getContext('2d') // 二维绘图
        ctx.drawImage(video, 0, 0, width, height) // 绘制canvas
        const dataURL = canvas.toDataURL('image/jpeg') // 转换为base64
        const file = that.dataURLtoFile(dataURL, '789.jpeg') //'789.jpeg'瞎写的图片名称  可替换为当前文件名
        // that.upload(file);  //上传图片到服务器方法
        // console.log(dataURL, file, '第一帧数据');
        this.url = dataURL
      })
    },
    //base64转二进制   dataurl：base64编码  filename：文件名
    dataURLtoFile(dataurl, filename) {
      const arr = dataurl.split(',') // 截取base64数据
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1]) // 转码
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/style/common.less';
.list-item {
  border-radius: 32px;
  box-shadow: 2px 2px 9px 1px rgba(3, 3, 3, 0.05),
    -2px -2px 9px 1px rgba(3, 3, 3, 0.05);
  background-color: #fff;
  padding: 48px;
  margin: 72px 0;
  .info {
    display: flex;
    .left {
      // flex: 1;
      width: 75%;
    }
    .right {
      margin: 0 0 0 24px;
    }
  }
  .pic {
    display: block;
    width: 427px;
    height: 240px;
  }
  .title {
    font-size: 54px;
    // white-space: nowrap;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .des {
    margin-top: 5px;
    font-size: 40px;
    color: @font-gray-1;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 50px;
  }
  .footer {
    font-size: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: @primary-font-color;
    margin: 20px 0 0;
    .icon {
      margin: 0 14px 0 0;
    }
    .mr {
      margin: 0 40px 0 0;
    }
  }
}
</style>
